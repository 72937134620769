// extracted by mini-css-extract-plugin
export var container = "contact-item-module--container--Civo5";
export var right = "contact-item-module--right--b9uLw";
export var containerMap = "contact-item-module--container-map--Cpzrj";
export var rightMap = "contact-item-module--right-map--STF2O";
export var innerContainer = "contact-item-module--inner-container--d+uL+";
export var text = "contact-item-module--text--OMMXH";
export var left = "contact-item-module--left--OzJ70";
export var rightText = "contact-item-module--right-text--IApcs";
export var map = "contact-item-module--map--nzYoo";
export var textHidden = "contact-item-module--text-hidden--tHwNO";
export var first = "contact-item-module--first--8ZGmd";
export var second = "contact-item-module--second--u+dMv";
export var third = "contact-item-module--third--cC3Ph";
export var ahrefLeft = "contact-item-module--ahref-left--7Z60r";