import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    videoLanding,
    video,
    player,
    content,
    contentLanding,
    left,
    title,
    text,
    right,
    gradient,
    titleImages,
    logo,
    logoXSB,
} from './why-us.module.scss';
import { grid } from '../../styles/grid.module.scss';
import LogoIcon from '../../assets/images/svg/logo.svg';
import videoSrc from '../../assets/videos/flamingo-video.mp4';

import Title from '../atoms/title';

const logoXSBSrc = '../../assets/images/svg/logo-xsb.svg';

interface IWhyUsProps {
    className?: string;
    id?: string;
    isLanding?: boolean;
}

const environment = process.env.GATSBY_ACTIVE_ENV || 'development';

const WhyUs: React.FC<IWhyUsProps> = ({ className = '', id, isLanding = false }) => {
    const { t } = useI18next();
    const autoPlay = environment !== 'development';
    return (
        <div className={`${container} ${grid} ${className}`} {...(id ? { id } : {})}>
            <div className={isLanding ? videoLanding : video}>
                <video
                    className={player}
                    muted={true}
                    playsInline={true}
                    loop={true}
                    autoPlay={autoPlay}
                >
                    <source src={videoSrc} type="video/mp4" />
                </video>
            </div>
            <div className={isLanding ? contentLanding : content}>
                <div className={left}>
                    <Title className={title}>
                        {t('whyUs.left.title.1')} <br />
                        <span className={gradient}>{t('whyUs.left.title.2')}</span>
                    </Title>
                    <p className={text}>{t('whyUs.left.text')}</p>
                </div>
                {!isLanding && (
                    <div className={right}>
                        <div className={titleImages}>
                            <LogoIcon className={logo} />
                            <StaticImage
                                className={logoXSB}
                                src={logoXSBSrc}
                                alt={t('logo.xsb.alt')}
                                quality={100}
                            />
                        </div>
                        <p className={text}>{t('whyUs.right.text')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WhyUs;
