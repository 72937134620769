import React, { useEffect, memo, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import { mapContainer } from './map.module.scss';

interface IMapProps {
    className?: string;
}

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || '';
const containerStyle = { width: '100%', height: '100%' };
const center = { lat: 52.2517, lng: 20.9794 };

const Map: React.FC<IMapProps> = ({ className = '' }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_API_KEY,
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    //Z uwagi że map jest widoczny tylko na :hover, zdecydowałem się użyć setTimeout. Przepisanie zbyt czasochłonne. googleApi + React = :(
    useEffect(() => {
        if (!map) return;
        setTimeout(() => {
            map.setZoom(17);
        }, 1000);
    }, [map]);

    return isLoaded ? (
        <div className={`${className} ${mapContainer}`}>
            <GoogleMap
                zoom={17}
                center={center}
                onLoad={onLoad}
                mapContainerStyle={containerStyle}
                onUnmount={onUnmount}
                options={{ disableDefaultUI: true }}
            >
                <Marker position={center} />
            </GoogleMap>
        </div>
    ) : (
        <></>
    );
};

export default memo(Map);
