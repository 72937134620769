import React, { useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, useStaticQuery } from 'gatsby';

import {
    landing,
    bgImage,
    containerTop,
    containerBottom,
    innerContainerTop,
    innerContainerBottom,
    text,
    titleBox,
    card,
    sliderButton,
} from './about-us.module.scss';
import { gridMedium } from '../../styles/grid.module.scss';
import paperBg from '../../assets/images/paper-bg2.webp';
import { IPerson } from '../../models/person.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getNodes } from '../../utils/get-nodes';

import Title from '../atoms/title';
import PersonCard from '../molecules/person-card';
import Slider from '../hoc/slider';

import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';

interface IAboutUsProps {
    className?: string;
    id?: string;
    isLanding?: boolean;
}

interface IAboutUsQueryResult {
    allPerson: IQueryAllResult<IPerson>;
}

const AboutUs: React.FC<IAboutUsProps> = ({ className = '', id, isLanding = false }) => {
    const { t } = useI18next();
    const prevSliderButton = useRef<HTMLButtonElement>(null);
    const nextSliderButton = useRef<HTMLButtonElement>(null);
    const { allPerson } = useStaticQuery<IAboutUsQueryResult>(query);
    const persons = getNodes(allPerson);

    const sliderOptionsWithNavigation = {
        ...sliderOptions,
        navigation: {
            nextEl: nextSliderButton.current,
            prevEl: prevSliderButton.current,
        },
    };

    return (
        <div className={`${className} ${isLanding ? landing : ''}`} {...(id ? { id } : {})}>
            <div
                className={`${gridMedium}  ${containerTop} ${bgImage}`}
                style={{ backgroundImage: `url(${paperBg})` }}
            >
                <div className={innerContainerTop}>
                    <div className={titleBox}>
                        <Title>
                            {t('about.top.title1')} <br /> <span>{t('about.top.title2')}</span>
                        </Title>
                    </div>
                    <p className={text}>{t('about.top.text')}</p>
                </div>
            </div>

            <div className={`${gridMedium} ${containerBottom}`}>
                <Slider className={innerContainerBottom} {...sliderOptionsWithNavigation}>
                    {persons.map((person) => {
                        return (
                            <div className={card} key={person.profileId}>
                                <PersonCard person={person} />
                            </div>
                        );
                    })}
                </Slider>
                <button ref={prevSliderButton} className={sliderButton}>
                    <ArrowLeft />
                </button>
                <button ref={nextSliderButton} className={sliderButton}>
                    <ArrowRight />
                </button>
            </div>
        </div>
    );
};

const sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    autoplay: { delay: 3000 },
    breakpoints: {
        700: {
            slidesPerView: 2,
        },
        1025: {
            slidesPerView: 3,
        },
    },
};

const query = graphql`
    query {
        allPerson(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...personFields
                }
            }
        }
    }
`;

export default AboutUs;
