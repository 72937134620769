import React from 'react';

import {
    img,
    name as nameCard,
    position as positionCard,
    text as textCard,
    ratio,
} from './person-card.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { relations } from '../../config/relations';
import useMedia from '../../hooks/use-media';
import { useTranslation } from '../../hooks/use-translation';

import { IPerson } from '../../models/person.model';

import RatioImage from '../atoms/ratio-image';

interface IPersonCardProps {
    className?: string;
    person: IPerson;
    NameTag?: React.ElementType;
    PositionTag?: React.ElementType;
}

const PersonCard: React.FC<IPersonCardProps> = ({
    className = '',
    person,
    NameTag = 'h3',
    PositionTag = 'h4',
}) => {
    const { media } = person;
    const { firstName, lastName, title, description } = useTranslation(
        person,
        translationKeys.person
    );
    const mediaItem = useMedia({ media, relation: relations.mainImage });

    return (
        <div className={className}>
            <RatioImage
                image={mediaItem.image}
                alt={mediaItem.alt}
                fallbackUrl={mediaItem.url}
                className={img}
                ratioClass={ratio}
            />
            <NameTag className={nameCard}>
                {firstName} {lastName}
            </NameTag>
            {title && <PositionTag className={positionCard}>{title}</PositionTag>}
            {description && <p className={textCard}>{description}</p>}
        </div>
    );
};

export default PersonCard;
