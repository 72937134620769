import React from 'react';
import { Link } from 'gatsby';

import {
    container,
    containerMap,
    text,
    textHidden,
    rightText,
    left,
    right,
    first,
    second,
    third,
    map,
    rightMap,
    innerContainer,
    ahrefLeft,
} from './contact-item.module.scss';

import Markdown from '../hoc/markdown';

export interface IContactItem {
    title: string;
    label?: string;
    link?: string;
    color: 'first' | 'second' | 'third';
    isMap?: boolean;
}

interface IContactItemProps {
    className?: string;
    contactItem: IContactItem;
    asLink: boolean;
}

const ContactItem: React.FC<IContactItemProps> = ({
    className = '',
    contactItem,
    children,
    asLink,
}) => {
    const { color, title, label, isMap, link } = contactItem;
    const colorClass = colorClasses[color];

    if (asLink && link) {
        return (
            <div className={`${className} ${isMap ? containerMap : container}`}>
                <div className={innerContainer}>
                    <div className={`${left} ${colorClass} `}>
                        <Link to={link}>
                            <h3 className={text}>{title}</h3>
                            <h3 className={`${textHidden}`}>{title}</h3>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={`${className} ${isMap ? containerMap : container}`}>
            <div className={innerContainer}>
                {isMap && (
                    <>
                        <div className={`${left} ${colorClass} `}>
                            <a
                                href={link}
                                target="_blank"
                                className={ahrefLeft}
                                rel="noreferrer noopener"
                            >
                                <h3 className={text}>{title}</h3>
                                <h3 className={`${textHidden}`}>{title}</h3>
                            </a>
                        </div>
                        <div className={` ${rightMap} ${colorClass} `}>
                            {label && <Markdown className={rightText}>{label}</Markdown>}
                            <div className={`${map} ${colorClass}`}>{children}</div>
                        </div>
                    </>
                )}

                {!isMap && (
                    <>
                        <div className={`${left} ${colorClass} `}>
                            <a href={`mailto:${link}`} className={ahrefLeft}>
                                <h3 className={text}>{title}</h3>
                                <h3 className={`${textHidden}`}>{title}</h3>
                            </a>
                        </div>

                        <div className={`${right}  ${colorClass} `}>
                            <a href={`mailto:${link}`} className={rightText}>
                                {link}
                            </a>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const colorClasses = {
    first: first,
    second: second,
    third: third,
};

export default ContactItem;
