import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import video from '../../assets/videos/home-eye-video.mp4';
import {
    container,
    containerLanding,
    player,
    content,
    title1,
    title2,
    image,
    itemContact,
} from './contact.module.scss';

import ContactItem, { IContactItem } from '../atoms/contact-item';
import Map from '../atoms/map';

const environment = process.env.GATSBY_ACTIVE_ENV || 'development';

interface IContactProps {
    className?: string;
    id?: string;
    isLanding?: boolean;
}

const Contact: React.FC<IContactProps> = ({ className = '', id, isLanding = false }) => {
    const autoPlay = environment !== 'development';
    const { t } = useI18next();
    const firstContact: IContactItem = {
        title: t('contact.label1'),
        link: t('contact.link1'),
        color: 'first',
    };
    const secondContact: IContactItem = {
        title: t('contact.label2'),
        link: t('contact.link2'),
        color: 'second',
    };
    const thirdContact: IContactItem = {
        title: t('contact.label3'),
        link: isLanding ? '/' : t('contact.link3'),
        color: 'third',
        label: t('contact.label'),
        isMap: true,
    };

    const contactItems = isLanding
        ? [firstContact, thirdContact]
        : [firstContact, secondContact, thirdContact];

    return (
        <div
            className={`${className} ${isLanding ? containerLanding : container}`}
            {...(id ? { id } : {})}
        >
            <div className={content}>
                <h2 className={title1}>
                    {!isLanding && (
                        <>
                            {t('contact.title1')} <br />
                        </>
                    )}

                    <span className={title2}>{t('contact.title2')}</span>
                </h2>
                <ul>
                    {contactItems.map((contactItem) => {
                        return (
                            <li key={contactItem.title}>
                                <ContactItem
                                    className={itemContact}
                                    contactItem={contactItem}
                                    asLink={isLanding && contactItem.link === '/'}
                                >
                                    {contactItem.isMap && <Map className={image} />}
                                </ContactItem>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <video
                className={player}
                muted={true}
                playsInline={true}
                loop={true}
                autoPlay={autoPlay}
            >
                <source src={video} type="video/mp4" />
            </video>
        </div>
    );
};

export default Contact;
