import React from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Keyboard } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/navigation';

export interface ISliderProps extends Omit<SwiperProps, 'modules'> {
    children?: React.ReactNode;
    loading?: boolean;
}

export interface ISlideData {
    isActive: boolean;
    isVisible: boolean;
    isDuplicate: boolean;
    isPrev: boolean;
    isNext: boolean;
}

const Slider: React.FC<ISliderProps> = ({ children, loading = false, ...rest }) => {
    return (
        <Swiper modules={[Autoplay, Navigation, Keyboard]} keyboard={true} {...rest}>
            {Array.isArray(children) &&
                children.map((child, index) => {
                    return (
                        <SwiperSlide key={(child.key && `slide-${child.key}`) || `slide-${index}`}>
                            {(slideData: ISlideData) => {
                                return React.cloneElement(child, {
                                    ...(typeof child.type === 'function' ? { slideData } : {}),
                                });
                            }}
                        </SwiperSlide>
                    );
                })}
        </Swiper>
    );
};

export default Slider;
