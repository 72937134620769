import React from 'react';
import { useLocation } from '@reach/router';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, content, header, label } from './stripe.module.scss';
import stripeBg from '../../assets/images/stripe-bg.webp';
import { translationKeys } from '../../config/translation-keys';
import { IStripe } from '../../models/stripe.model';
import { useTranslation } from '../../hooks/use-translation';
import { isInternalLink } from '../../utils/is-internal-link';

interface IStripeProps {
    className?: string;
    stripe: IStripe;
}

const Stripe: React.FC<IStripeProps> = ({ className = '', stripe }) => {
    const { hostname } = useLocation();
    const { t } = useI18next();
    const { url, text } = useTranslation(stripe, translationKeys.stripe);

    const isUrlInternal = url && isInternalLink(url, hostname);
    const Tag = getTag(!!isUrlInternal);
    const tagProps = isUrlInternal
        ? {
              to: url,
          }
        : {
              href: url,
              target: '_blank',
              rel: 'noopener noreferrer',
          };

    return (
        <Tag
            className={`${container} ${className}`}
            style={{ backgroundImage: `url(${stripeBg})` }}
            {...tagProps}
        >
            <div className={content}>
                <h2 className={header}>{t('stripe.header')}</h2>
                <p className={label}>{text}</p>
            </div>
        </Tag>
    );
};

function getTag(isUrlInternal: boolean): React.ElementType | typeof Link {
    if (isUrlInternal) return Link;
    return 'a';
}

export default Stripe;
