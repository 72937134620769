import React, { useEffect, useRef } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    hiddenTitle,
    player,
    content,
    logo,
    title,
    stripeBox,
    scrollTip,
} from './home-video-banner.module.scss';
import videoDesktop from '../../assets/videos/home-video-desktop.mp4';
import LogoIcon from '../../assets/images/svg/logo.svg';
import { IStripe } from '../../models/stripe.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getNodes } from '../../utils/get-nodes';
import { useIntroContext } from '../../contexts/intro-context';
import { isMobileDevice } from '../../utils/is-mobile-device';
const banner = '../../assets/images/banner.png';

import Markdown from '../hoc/markdown';
import Stripe from '../molecules/stripe';

interface IHomeVideoBannerProps {
    className?: string;
    isLanding?: boolean;
}

interface IHomeVideoBannerQueryResult {
    allStripe: IQueryAllResult<IStripe>;
}

const HomeVideoBanner: React.FC<IHomeVideoBannerProps> = ({
    className = '',
    isLanding = false,
}) => {
    const { t } = useI18next();
    const { isComplete } = useIntroContext();
    const videoRef = useRef<HTMLVideoElement>(null);
    const { allStripe }: IHomeVideoBannerQueryResult = useStaticQuery(query);
    const stripes = getNodes(allStripe);
    const isMobile = isMobileDevice();

    useEffect(() => {
        if (!videoRef.current) return;
        if (!isComplete) return;
        videoRef.current.play();
    }, [isComplete]);

    return (
        <div className={`${container} ${className}`}>
            {isMobile ? (
                <StaticImage src={banner} alt="" quality={100} className={player} />
            ) : (
                <video
                    ref={videoRef}
                    className={player}
                    muted={true}
                    playsInline={true}
                    loop={true}
                    controls={false}
                >
                    <source src={videoDesktop} type="video/mp4" />
                </video>
            )}
            <h1 className={content}>
                <span className={hiddenTitle}>{t('logo.alt')}</span>
                <LogoIcon className={logo} />
                <Markdown className={title}>{t('home.banner.title')}</Markdown>
            </h1>
            {!isLanding && stripes.length > 0 && (
                <Stripe className={stripeBox} stripe={stripes[0]} />
            )}
            {isLanding && <Markdown className={scrollTip}>{t('landing.home.video.tip')}</Markdown>}
        </div>
    );
};

const query = graphql`
    query {
        allStripe(sort: { fields: createdAt, order: DESC }) {
            edges {
                node {
                    ...stripeFields
                }
            }
        }
    }
`;

export default HomeVideoBanner;
